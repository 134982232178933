export default {
    namespaced: true,
    state: {
        isShowModal: false,
        wysiwygInstance: null,
        prevFormula: '',
        currentFormula: '',
        onSave: null,
    },
    getters: {
        isShowModal: state => state.isShowModal,
        currentFormula: state => state.currentFormula,
        isEditMode: state => Boolean(state.onSave),
        isFormulaChanged: state => state.currentFormula !== state.prevFormula,
    },
    mutations: {
        setShowModal(state, value) {
            state.isShowModal = value;
        },
        setWysiwygInstance(state, value) {
            state.wysiwygInstance = value;
        },
        setCurrentFormula(state, value) {
            state.currentFormula = value;
        },
        setPrevFormula(state, value) {
            state.prevFormula = value;
        },
        setSaveCallback(state, value) {
            state.onSave = value;
        },
    },
    actions: {
        setShowModal({ commit }, payload) {
            commit('setShowModal', payload);
        },
        showModal({ commit }) {
            commit('setShowModal', true);
        },
        hideModal({ commit }) {
            commit('setShowModal', false);
        },
        setWysiwygInstance({ commit }, payload) {
            commit('setWysiwygInstance', payload);
        },
        setCurrentFormula({ commit }, payload) {
            commit('setCurrentFormula', payload);
        },
        handleModalSave({ state }) {
            if (!state.wysiwygInstance) return;

            if (state.onSave) {
                state.onSave(state.currentFormula);
            } else {
                state.wysiwygInstance.commands.insertFormula(state.currentFormula);
            }
        },
        handleModalClose({ commit }) {
            commit('setShowModal', false);
            commit('setWysiwygInstance', null);
            commit('setCurrentFormula', '');
            commit('setPrevFormula', '');
            commit('setSaveCallback', null);
        },
        setFormulaEdit({ commit }, { wysiwygInstance, onSave, formula }) {
            commit('setShowModal', true);
            commit('setCurrentFormula', formula);
            commit('setPrevFormula', formula);
            commit('setSaveCallback', onSave);
            commit('setWysiwygInstance', wysiwygInstance);
        },
    },
};
