import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';


/**
 * get solutions list
 * @param {number|string} centerId
 * @param {Object} params search, sort and filter params
 * @returns Promise
 */
export const getSolutions = (centerId, params, config) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions`, { params }, config);

/**
 * get solutions status list
 * @returns Promise
 */
export const getSolutionsStatusList = () => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/dictionaries/solutions/status`);

/**
 * add solution to list
 * @param {number|string} centerId
 * @param {Object} solutionsData data for added solution
 * @param {string} solutionsData.title solution title
 * @param {string} solutionsData.completion_type solution completion type 'viewed' | 'completed' | 'marked'
 * @param {number[]} solutionsData.responsibles array of responsibles ids
 * @returns Promise
 */
export const addSolution = (centerId, solutionsData) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions`, { ...solutionsData });

/**
 * update solution by id
 * @param {number| string} centerId
 * @param {number|string} solutionId solution id
 * @param {Object} solutionData solution data
 * @param {string} solutionData.title solution title
 * @returns Promise
 */
export const updateSolution = (centerId, solutionId, solutionData) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${solutionId}`;

    return axios.put(url, solutionData);
};

/**
 * update solution access
 * @param {number| string} centerId development center
 * @param {number|string} solutionId solution id
 * @param {Object} solutionAccessData solution data
 * @param {boolean} solutionData.is_private solution is private
 * @param {array} solutionData.members solution title
 * @returns Promise
 */
export const updateSolutionAccess = (centerId, solutionId, solutionAccessData) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${solutionId}/access`;

    return axios.post(url, solutionAccessData);
};

/**
 * remove solution by id
 * @param {number|string} centerId
 * @param {number|string} solutionId solution id
 * @returns Promise
 */
export const removeSolution = (centerId, solutionId) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${solutionId}`;

    return axios.delete(url);
};

/**
 * restore solution by id
 * @param {number|string} centerId
 * @param {number|string} solutionId solution id
 * @returns Promise
 */
export const restoreSolution = (centerId, solutionId) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${solutionId}/restore`;

    return axios.post(url);
};

/**
 * copy solution by id
 * @param {number|string} centerId
 * @param {number|string} solutionId solution id
 * @param {string} title solution title
 * @returns Promise
 */
export const copySolution = (centerId, solutionId, title) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${solutionId}/copy`;

    return axios.post(url, { title });
};

/**
 * get solution version
 * @param {number|string} solutionId  solution id
 * @param {number|string} versionId version id
 * @returns Promise
 */
export const getSolutionVersion = (solutionId, versionId) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}`;

    return axios.get(url);
};

/**
 * add version to solution with given id
 * @param {string|number} solutionId solution id
 * @param {Object} versionData version data
 * @param {string} versionData.title version title
 * @param {string} versionData.completion_type version completion type 'viewed' | 'completed' | 'marked'
 * @param {number[]|string[]} versionData.responsibles version responsibles ids array
 */
export const addSolutionVersion = (solutionId, versionData) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions`;

    return axios.post(url, versionData);
};

/**
 * update version of solution with given solution and version id
 * @param {string|number} solutionId solution id
 * @param {number|string} versionId version id
 * @param {Object} versionData version data
 * @param {string} versionData.title version title
 * @param {string} versionData.status version status 'draft' | 'published' | 'saved'
 * @param {string} versionData.completion_type version completion type 'viewed' | 'completed' | 'marked'
 * @param {number[]|string[]} versionData.responsibles version responsibles ids array
 */
export const updateSolutionVersion = (solutionId, versionId, versionData) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}`;

    return axios.put(url, versionData);
};

/**
 * remove version of solution with given solution and version id
 * @param {number|string} solutionId solution id
 * @param {number|string} versionId version id
 * @returns Promise
 */
export const removeSolutionVersion = (solutionId, versionId) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}`;

    return axios.delete(url);
};

/**
 * restore version of solution with given solution and version id
 * @param {number|string} solutionId solution id
 * @param {number|string} versionId version id
 * @returns Promise
 */
export const restoreSolutionVersion = (solutionId, versionId) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}/restore`;

    return axios.post(url);
};

/**
 * copy version of solution with given solution and version id
 * @param {number|string} solutionId solution id
 * @param {number|string} versionId version id
 * @returns Promise
 */
export const copySolutionVersion = (solutionId, versionId, title) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}/copy`;

    return axios.post(url, { title });
};


/**
 * get solutions structure
 * @param {number|string} solutionId solutionId id
 * @param {number|string} versionId version id
 * @returns Promise
 */
export const getSolutionsStructure = (solutionId, versionId, params) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}/structure`;

    return axios.get(url, { params });
};


/**
 * update solution structure with given version id
 * @param {number|string} solutionId solution id
 * @param {number|string} versionId version id
 * @param {Object} structureData data module
 * @param {Array} structureData.modules modules array
 * @returns Promise
 */
export const updateSolutionStructure = (solutionId, versionId, structureData) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}/structure/order`;

    return axios.post(url, { ...structureData });
};

/**
 * update version access
 * @param {number|string} solutionId solution id
 * @param {number|string} versionId version id
 * @param {Object} accessData access data
 * @param {1|0} accessData.is_private access data pivate access
 * @param {Array} accessData.added_users access data added users ids array
 * @returns Promise
 */
export const updateVersionAccess = (solutionId, versionId, accessData) => {
    const url = `${BASE_URI_DEVELOPMENT_CENTER}/solutions/${solutionId}/versions/${versionId}/access`;

    return axios.post(url, { ...accessData });
};

/**
 * get project by id
 * @param {number|string} centerId development center id
 * @param {number|string} projectId project id
 * @returns Promise
 */
export const getProjectById = (centerId, projectId) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${projectId}`);

/**
 * get versions list
 * @param {number|string} projectId project id
 * @param {Object} params search, sort and filter params
 * @returns Promise
 */
export const getVersionsList = (projectId, params) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/solutions/${projectId}/versions`, { params });

/**
 * update project status
 * @param {number|string} centerId development center id
 * @param {number|string} projectId project id
 * @param {Object} is_archival is archival status
 * @returns Promise
 */
export const updateProjectStatus = (centerId, projectId, status) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${centerId}/solutions/${projectId}/change-status`, status);

export const createCourseExportPdfReport = ({ projectId, courseId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/solutions/${projectId}/versions/${courseId}/export`);
