<template>
    <SbsTooltip
        class="CopyIdWithTooltip"
        :content="tooltipText">
        <div
            class="CopyIdWithTooltip__Wrapper"
            :class="{ 'CopyIdWithTooltip__Wrapper--GrayTheme': isGrayTheme }"
            @click.stop.prevent="handleClickCopyId">
            <slot>
                <div
                    v-if="!withoutPrefix"
                    class="CopyIdWithTooltip__Title">
                    ID
                </div>
                <div class="CopyIdWithTooltip__Id">
                    {{ id }}
                </div>
            </slot>
        </div>
    </SbsTooltip>
</template>

<script>
import useSnackbar from '@/hooks/snackbars.js';
import { copyValueToClipboard } from '@/utils/utils.js';

export default {
    name: 'CopyIdWithTooltip',
    methods: {
        handleClickCopyId() {
            copyValueToClipboard(this.id);

            this.createSnackbar({
                message: this.copyMessage,
                type: 'success',
            });
        },
    },
    setup() {
        const { createSnackbar } = useSnackbar();

        return {
            createSnackbar,
        };
    },
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
        tooltipText: {
            type: String,
            default: 'Скопировать ID',
        },
        copyMessage: {
            type: String,
            default: 'ID скопировано',
        },
        isGrayTheme: {
            type: Boolean,
            default: false,
        },
        withoutPrefix: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang='scss' scoped>
.CopyIdWithTooltip {
    cursor: pointer;

    @include typo-secondary-book;

    &:hover {
        opacity: 0.4;
    }

    &__Wrapper {
        display: flex;
        gap: 5px;
        align-items: baseline;
        color: $black;

        &--GrayTheme {
            color: $gray-deep;
        }
    }

    &__Title {
        font-weight: 450;
    }
}
</style>
