export const solutionsState = {
    solutions: [],
    counters: {},
    currentPage: 1,
    lastPage: 1,
    error: '',
    loading: false,
    counts: {},
    solutionsStatusList: [],
    removedSolutionIndex: 0,
    removedVersionIndex: 0,
    accessDataForRestore: null,
    currentProject: null,
    versions: [],
    versionsCounts: {},
    currentVersionPage: 0,
    versionsLastPage: 1,
    versionsTotalCount: 0,
    versionsError: '',
    vesionsLoading: false,
    version: {},
    versionLoading: false,
    versionHomeworksActivities: [],
    versionHomeworksActivitiesTotal: null,
    accessDenied: false,
    IsDefaultSolutionFilters: false,
};
