import * as yup from 'yup';
import unzip from 'lodash/unzip.js';
import zipObjectDeep from 'lodash/zipObjectDeep.js';
import isNil from 'lodash/isNil.js';
import { filesize, getHtmlTextLength } from '@/utils/utils.js';
import * as flashCardsConstants from '@/components/Blocks/BlockFlashCards/constants/index.js';
import { TEXT_WITH_ICONS_STYLE_NAMES } from '@/constants/index.js';

/**
 * @param {import('yup').Schema} scheme
 * @param {any} obj
 * @param {object} [context]
 * @returns {{}|object}
 */
export const validationScheme = (scheme, obj, context) => {
    const emptyErrors = {};

    try {
        scheme.validateSync(obj, {
            abortEarly: false,
            context,
        });

        return emptyErrors;
    } catch (e) {
        const yupErrors = e.inner?.map(err => [err.path, err.message]);

        if (yupErrors) {
            const yupErrorUnzipped = unzip(yupErrors);

            return zipObjectDeep(yupErrorUnzipped[0], yupErrorUnzipped[1]);
        }

        console.error('invalid schema', e);

        return emptyErrors;
    }
};

export const cyrillicEmailValidator = value => {
    if (!value) return true;

    return /^[a-zA-Zа-яА-Я0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Zа-яА-Я0-9](?:[a-zA-Zа-яА-Я0-9-]{0,61}[a-zA-Zа-яА-Я0-9])?(?:\.[a-zA-Zа-яА-Я0-9](?:[a-zA-Zа-яА-Я0-9-]{0,61}[a-zA-Zа-яА-Я0-9])?)*$/.test(value);
};

export const phoneValidator = value => {
    if (!value) return true;

    return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,15}(\s*)?$/.test(value) ||
        /^[0-9][0-9][0-9]$/g.test(value);
};

export const peopleActivityValidator = item => {
    if (!item) return false;

    const {
        title,
        description,
        phone,
        email,
    } = item.content;

    const isValid = Boolean(title) &&
        Boolean(description) &&
        phoneValidator(phone) &&
        cyrillicEmailValidator(email);

    return isValid;
};

export const questionsAnswersActivityValidator = item => {
    if (!item) return false;

    const { text } = item.content;

    return Boolean(text);
};

// eslint-disable-next-line complexity
export const textWithIconsActivityValidator = (itemContent, titleVisibility, numerationVisibility) => {
    if (!itemContent) return false;

    const titleMaxLength = 100;
    const descriptionMaxLength = 500;

    const {
        title,
        description,
        iconStyle,
        iconName,
    } = itemContent;

    if (!iconStyle || (!numerationVisibility && !iconName)) return false;

    if (iconStyle === TEXT_WITH_ICONS_STYLE_NAMES.IMAGE && !itemContent.attachment_id) return false;

    return titleVisibility
        ? Boolean(title) && title.length <= titleMaxLength && getHtmlTextLength(description) <= descriptionMaxLength
        : Boolean(description) && getHtmlTextLength(description) <= descriptionMaxLength;
};

export const urlValidator = item => {
    try {
        const url = new URL(item);

        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (_) {
        return false;
    }
};

export const cleanTagsFromString = string => string.replace(/<\/?[^>]+(>|$)/g, '');

export const froalaTextValidator = value => {
    const clearRegex = /<p data-f-id="pbf".*Froala Editor<\/a><\/p>$/g;

    return cleanTagsFromString((value || '').replace(clearRegex, ''));
};

export const flashCardsItemValidator = item => {
    const isFrontTitleError = !item.front.title || (item.front.title.length > flashCardsConstants.MAX_TITLE_LENGTH);
    const isBackDescriptionError = !item.back.description || (item.back.description.length > flashCardsConstants.MAX_DESCRIPTION_LENGTH);
    const isBackTitleRequiredError = item.back.withTitle && !item.back.title;
    const isBackTitleLengthError = item.back.withTitle && (item.back.title?.length > flashCardsConstants.MAX_TITLE_LENGTH);
    const isBackTitleError = isBackTitleRequiredError || isBackTitleLengthError;

    return isFrontTitleError || isBackDescriptionError || isBackTitleError;
};

/**
 * @typedef {Object} ValidationOptions
 * @property {number} [minSize]
 * @property {number} [maxSize]
 * @property {Array<string>} [extensions]
 * @property {Array<string>} [types]
 */

/**
 * @param {File|null|undefined} file
 * @param {ValidationOptions} [options]
 *
 * @throws {import('yup').ValidationError}
 */
export const validateFile = (file, options) => {
    if (!file) {
        throw new Error('Необходимо указать файл');
    }

    let fileSchema = yup.mixed().required();

    if (!isNil(options?.types) && options.types.length > 0) {
        fileSchema = fileSchema.test({
            name: 'file-types',
            message: 'Неверный формат файла для загрузки',
            test: fileVal => options.types.includes(fileVal.type),
        });
    }

    if (!isNil(options?.extensions) && options.extensions.length > 0) {
        fileSchema = fileSchema.test({
            name: 'file-extension',
            message: 'Неверный формат файла для загрузки',
            test: fileVal => options.extensions.some(ext => {
                if (ext.startsWith('.')) {
                    return fileVal.name.endsWith(ext);
                }

                return fileVal.name.endsWith(`.${ext}`);
            }),
        });
    }

    if (!isNil(options?.minSize)) {
        fileSchema = fileSchema.test({
            name: 'file-min-size',
            message: `Минимальный размер файла ${filesize(options.minSize)}`,
            test: fileVal => fileVal.size >= options.minSize,
        });
    }

    if (!isNil(options?.maxSize)) {
        fileSchema = fileSchema.test({
            name: 'file-max-size',
            message: `Максимальный размер файла ${filesize(options.maxSize)}`,
            test: fileVal => fileVal.size <= options.maxSize,
        });
    }

    fileSchema.validateSync(file);
};
