export const QUESTION_TYPES = Object.freeze({
    SINGLE_CHOICE: 'OnlyChoice',
    MULTIPLE_ANSWERS: 'MultipleChoice',
    LIKERT_SCALE: 'LikertScale',
    OPEN_QUESTION: 'OpenQuestion',
    SORTING: 'Sorting',
    MATCHING: 'Matching',
    GAPS_IN_TEXT: 'GapsInTheText',
    INFORMATION: 'Information',
    DISTRIBUTE_BY_CATEGORY: 'DistributionByCategory',
    QUIZ_CARDS: 'QuizCards',
});

export const QUESTION_TYPES_INTERVIEW = [
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.LIKERT_SCALE,
    QUESTION_TYPES.OPEN_QUESTION,
    QUESTION_TYPES.GAPS_IN_TEXT,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.QUIZ_CARDS,
    QUESTION_TYPES.INFORMATION,
];

export const QUESTION_TYPES_TEST = [
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.GAPS_IN_TEXT,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.MATCHING,
    QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY,
    QUESTION_TYPES.QUIZ_CARDS,
    QUESTION_TYPES.INFORMATION,
];

export const TestQuizTypes = Object.freeze({
    TEST: 'test',
    INTERVIEW: 'interview',
});

export const TestOrQuizTitle = {
    [TestQuizTypes.TEST]: 'Тест',
    [TestQuizTypes.INTERVIEW]: 'Опрос',
};

export const MicroTestOrQuizTitle = {
    [TestQuizTypes.TEST]: 'Микротест',
    [TestQuizTypes.INTERVIEW]: 'Микроопрос',
};

export const REACTION_TYPES = {
    NONE: 'none',
    EVERY: 'every',
    OVERALL: 'overall',
};

export const LIKERT_SCALE_TYPES = {
    NUMBER: 'number',
    TEXT: 'text',
    TEXT_WITH_NUMBER: 'text-with-number',
};

export const QuestionsResultsType = Object.freeze({
    USER_ONLY: 'userOnly',
    USER_AND_RIGHT: 'userAndRight',
    NONE: 'none',
});

export const SummaryReactionTypes = Object.freeze({
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
});

export const QUESTION_TYPES_TEXT = Object.freeze({
    [QUESTION_TYPES.SINGLE_CHOICE]: 'Единичный выбор',
    [QUESTION_TYPES.MULTIPLE_ANSWERS]: 'Множественный выбор',
    [QUESTION_TYPES.LIKERT_SCALE]: 'Шкала Ликерта',
    [QUESTION_TYPES.OPEN_QUESTION]: 'Открытый вопрос',
    [QUESTION_TYPES.SORTING]: 'Сортировка',
    [QUESTION_TYPES.MATCHING]: 'Установка соответствия',
    [QUESTION_TYPES.GAPS_IN_TEXT]: 'Пропуски в тексте',
    [QUESTION_TYPES.INFORMATION]: 'Информационный слайд',
    [QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY]: 'Распределение по категориям',
    [QUESTION_TYPES.QUIZ_CARDS]: 'Квиз-карточки',
});

export const QUESTION_LABEL_TEXT = {
    [QUESTION_TYPES.SINGLE_CHOICE]: 'Выберите один вариант ответа',
    [QUESTION_TYPES.MULTIPLE_ANSWERS]: {
        [TestQuizTypes.TEST]: 'Выберите несколько вариантов ответа',
        [TestQuizTypes.INTERVIEW]: 'Выберите несколько вариантов ответа',
    },
    [QUESTION_TYPES.LIKERT_SCALE]: 'Отметьте на шкале наиболее подходящий вариант ответа',
    [QUESTION_TYPES.OPEN_QUESTION]: 'Запишите свой ответ в поле ввода',
    [QUESTION_TYPES.SORTING]: 'Расставьте тезисы в правильном порядке',
    [QUESTION_TYPES.MATCHING]: 'Сопоставьте пары для элементов (может быть несколько соответствий для одного элемента)',
    [QUESTION_TYPES.GAPS_IN_TEXT]: 'Заполните пропуски в тексте',
    [QUESTION_TYPES.INFORMATION]: 'Информационный слайд',
    [QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY]: 'Распределите карточки по категориям',
    [QUESTION_TYPES.QUIZ_CARDS]: 'Определите верный вариант карточки',
};

export const QUESTION_TYPES_FOR_DISABLE_SHAKE_ANSWERS = [
    QUESTION_TYPES.LIKERT_SCALE,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.OPEN_QUESTION,
];

export const MAX_TESTQUIZ_TITLE_LENGTH = 175;
export const MAX_TEST_DESCRIPTION_LENGTH = 2000;
export const MAX_QUIZ_DESCRIPTION_LENGTH = 500;
export const MAX_QUESTIONS = 50;
export const MAX_HOURS = 12;
export const MAX_QUESTION_TITLE = 175;
export const MAX_QUESTION_DESCRIPTION = 370;
export const MAX_INFORMATION_DESCRIPTION = 4000;
export const MAX_ANSWERS = 10;
export const MIN_ANSWERS = 2;
export const MIN_QUIZ_CARDS = 2;
export const MAX_QUIZ_CARDS = 4;
export const MAX_MATCH_ITEMS_NUMBER = 20;
export const MIN_MATCH_ITEMS_NUMBER = 2;
export const MIN_ANSWERS_GAP_IN_TEXT = 1;
export const MAX_LENGTH_TEXT_GAP_IN_TEXT = 3000;
export const MAX_LENGTH_ANSWER = 300;
export const MAX_LENGTH_COMMENT = 300;
export const MAX_LENGTH_ANSWER_LIKERT = 50;
export const MAX_LENGTH_ANSWER_MATCHTEXT = 100;
export const MAX_LENGTH_ANSWER_GAPSTEXT = 50;

export const MAX_LENGTH_ANSWER_CATEGORY_TITLE = 50;
export const MIN_LENGTH_ANSWER_CATEGORY_ITEMS = 2;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEMS = 4;

export const MAX_LENGTH_ANSWER_CATEGORY_COUNT = 4;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEM_TEXT = 100;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEM_COUNT_MIN = 1;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEM_COUNT_MAX = 24;
export const MIN_ANSWER_MATCHING_COUNT = 4;
export const MIN_ANSWER_LEVEL = 1;
export const DEFAULT_LIKERT_ANSWERS_LENGTH = 5;

export const MAX_LENGTH_ANSWER_QUIZCARD_TEXT = 100;

export const MATCHING_QUESTION_COLUMN_TYPES = {
    RIGHT: 'right',
    LEFT: 'left',
};

export const SHAKE_ANSWER_DISABLED_TOOLTIP = {
    default: 'Выдавать ответы учащимся в случайном порядке',
    [QUESTION_TYPES.LIKERT_SCALE]: 'Нельзя задать данный параметр когда выбрана шкала Ликерта',
    [QUESTION_TYPES.OPEN_QUESTION]: 'Нельзя задать данный параметр когда выбран открытый вопрос',
    [QUESTION_TYPES.SORTING]: 'Нельзя выключить данный параметр когда выбрана сортировка',
    [QUESTION_TYPES.GAPS_IN_TEXT]: 'Нельзя задать данный параметр когда выбрано дополнение к тексту',
};

export const QUESTION_TYPES_WITH_BLOCK_TYPE = {
    [TestQuizTypes.TEST]: [
        QUESTION_TYPES.SINGLE_CHOICE,
        QUESTION_TYPES.MULTIPLE_ANSWERS,
        QUESTION_TYPES.SORTING,
        QUESTION_TYPES.GAPS_IN_TEXT,
    ],
    [TestQuizTypes.INTERVIEW]: [
        QUESTION_TYPES.SINGLE_CHOICE,
        QUESTION_TYPES.MULTIPLE_ANSWERS,
        QUESTION_TYPES.SORTING,
        QUESTION_TYPES.LIKERT_SCALE,
        QUESTION_TYPES.OPEN_QUESTION,
    ],
};

export const VISIBILITY_SHUFFLE_ANSWERS = [
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.MATCHING,
    QUESTION_TYPES.QUIZ_CARDS,
];

export const VISIBILITY_MIN_ANSWERS = [
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.GAPS_IN_TEXT,
    QUESTION_TYPES.MATCHING,
    QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY,
];

export const DistributionCategoryFormats = {
    TEXT: 'text',
    IMAGE: 'image',
    TEXT_WITH_IMAGE: 'text_with_image',
};

export const DistributionCategoryCardFormats = {
    TEXT: 'text',
    IMAGE: 'image',
    TEXT_WITH_IMAGE: 'text_with_image',
};

export const QuizCardFormats = {
    TEXT: 'text',
    IMAGE: 'image',
    TEXT_WITH_IMAGE: 'text_with_image',
};
